/* eslint-disable react/display-name */
import React from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core';
import {
  ContentfulAsset,
  ContentfulComponentCtaButton,
  ContentfulGlobalWebsiteModalContent,
} from '../../graphql-types';
import Typography from './Typography';
import Button from './Button';
import { renderContentfulRichText } from '../utils/renderContentfulRichText';
import styled, { ThemeContext } from 'styled-components';
import ContentfulImage from './ContentfulImage';
import { getTranslation } from '../services/translation.service';
import { useLocalization } from 'gatsby-theme-i18n';

const DEFAULT_DELAY_MS = 2000;

const modalRichTextOptions: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      // Contentful seems to add empty paragraphs at the end of rich-text
      // cf: https://github.com/contentful/rich-text/issues/101
      const isEmptyChildren = children?.toString()?.trim() === '';
      if (isEmptyChildren) {
        return null;
      }

      return (
        <Typography as="p" variant="body" className="mt-s3 lg:mt-s4 first:mt-0">
          {children}
        </Typography>
      );
    },
    [BLOCKS.HEADING_1]: (node, children) => (
      <Typography
        as="h1"
        variant="h1"
        className="mt-s1 first:mt-0 text-secondary"
      >
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography as="h2" variant="h2" className="mt-s1 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography as="h3" variant="h3" className="mt-s1 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography as="h4" variant="h4" className="mt-s1 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography as="h5" variant="h4" className="mt-m3 first:mt-0">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography as="h6" variant="h4" className="mt-s1 first:mt-0">
        {children}
      </Typography>
    ),
  },
};

const StyledDialog = styled(Dialog)`
  .MuiDialogContent-root {
    padding: 0;
    width: 100%;
  }
  .MuiPaper-root {
    border: 1px solid ${(props) => props.theme.color.black};
    border-radius: 0px;
  }
`;

const ModalRichTextContainer = styled.div`
  strong {
    font-weight: 700;
  }
`;

const ClipPathContainer = styled.div`
  clip-path: polygon(
    0 0,
    0 100%,
    calc(100% - ${(props) => props.theme.spacing.s4}) 100%,
    100% calc(100% - ${(props) => props.theme.spacing.s4}),
    100% 0
  );
`;

interface GlobalSiteModalProps {
  id?: string;
  content?: ContentfulGlobalWebsiteModalContent;
  image?: ContentfulAsset;
  linkButton?: ContentfulComponentCtaButton;
  delay?: number;
}
const GlobalSiteModal: React.FC<GlobalSiteModalProps> = (props) => {
  const { id, content, linkButton, image, delay } = props;
  const cookieKeyName = `global-site-modal-${id}`;
  const theme = React.useContext(ThemeContext);
  const { locale } = useLocalization();

  // modal state and actions
  const isDesktopSize = useMediaQuery(`(min-width:${theme.breakpoint.sm})`);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const closeText = getTranslation('Close', locale);

  React.useEffect(() => {
    // get cookie and auto-open modal if they have not accepted the agreement
    const firstVisitCookieValue = document.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith(`${cookieKeyName}=`))
      ?.split('=')[1];
    if (!firstVisitCookieValue || firstVisitCookieValue === 'false') {
      document.cookie = `${cookieKeyName}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
      setTimeout(() => {
        setOpen(true);
      }, delay || DEFAULT_DELAY_MS);
    }
  }, []);

  return (
    <StyledDialog
      fullScreen={!isDesktopSize}
      maxWidth="md"
      open={open}
      onClose={handleClose}
      aria-describedby="global-site-modal"
    >
      <DialogContent id="global-site-modal">
        <div className="w-full lg:flex lg:h-[600px]">
          {image && (
            <ClipPathContainer className="lg:h-full lg:w-1/2">
              <ContentfulImage
                image={image}
                alt={image.description || ''}
                className="h-[83vw] max-h-[300px] lg:h-full lg:max-h-full"
                objectPosition="center 70%"
              />
            </ClipPathContainer>
          )}
          <div className="p-s4 lg:h-full lg:flex lg:flex-col lg:justify-center lg:w-1/2">
            <ModalRichTextContainer>
              {content &&
                renderContentfulRichText(content, modalRichTextOptions)}
            </ModalRichTextContainer>
            <div className="flex mt-s3 lg:mt-s4">
              {linkButton && (
                <Button
                  link={linkButton.page?.slug || linkButton.externalUrl}
                  openInNewWindow={linkButton.openInNewWindow}
                  variant={linkButton.variant}
                >
                  {linkButton.text}
                </Button>
              )}
              <Button
                variant="secondary"
                onClick={handleClose}
                className="ml-s2"
              >
                {closeText}
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default GlobalSiteModal;
