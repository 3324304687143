export const checkSegmentInURL = (url: string, word: string) => {
  try {
    const urlObj = new URL(url);
    const segments = urlObj.pathname.split('/').filter((part) => part !== '');
    return segments.includes(word);
  } catch (error) {
    //console.error('Invalid URL:', error);
    return false;
  }
};

export const checkFirstPartForString = (
  relativePath: string,
  searchString: string,
) => {
  const firstPart = relativePath.split('/', 1)[0]; // Split the path and get the first segment
  return firstPart.includes(searchString); // Check if the first part includes the searchString
};
